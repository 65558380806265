import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import flatten from 'lodash/flatten';
import Permission from 'models/Permission';
import React, { FunctionComponent, lazy, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Redirect, Switch } from 'react-router-dom';

import BlockSpinner from 'shared-components/BlockSpinner';
import GoogleAnalytics from 'shared-components/GoogleAnalytics';
import LayoutTemplate from 'shared-components/layout/LayoutTemplate';
import {
  CURRENT_INVITATION_CODE,
  LOGIN_CONFIRMATION_REDIRECT,
  PREPARE_LOGIN,
  TOKEN_STORAGE_KEY,
} from 'utils/constants';
import { useSegement } from 'utils/hooks';
import { SentryRoute as Route } from 'utils/route';
import { PermissionContext } from '../context';
import { RoutesGroup } from './RoutesGroup';
import LoginConfirmation from './LoginConfirmation';
import { isSubadmin } from 'utils/functions';
import useSWR from 'swr';
import { Profile } from 'models/Profile';
import ProfileSectionModal from './ProfileSettings/ProfileSectionModal';
import rules from 'assets/configs/rules.json';
import { APP_REGION } from 'utils/constants';
import UserSpaceContext from 'context/userSpace';
import { Me } from 'models/Me';
import CompletedProfileModal from './ProfileSettings/CompletedProfileModal';

const Login = lazy(() => import('./Login'));
const InvitationPage = lazy(() => import('./Invitation'));
const CurrentStreamingProduct = lazy(() => import('./CurrentStreamingProduct'));
const PayUpKYC = lazy(() => import('./PayUpKYC'));
const AuthCallback = lazy(() => import('./AuthCallback'));
const LogoutCallback = lazy(() => import('./LogoutCallback'));
const RedirectCallback = lazy(() => import('./RedirectCallback'));
const ExpiredSubscription = lazy(() => import('./Subscription/Expired'));

export const PERMISSIONS_URL = `/user-service/api/workspace/permissions`;

interface RoutesProps {
  completedAuth: boolean;
}

const Routes: FunctionComponent<RoutesProps> = ({ completedAuth }: RoutesProps) => {
  const { isAuthenticated } = useAuth0();
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [userSpaceContext, setUserSpaceContext] = useState<Me>();
  const [loading, setLoading] = useState(false);
  const isLoggedIn = isAuthenticated || !!localStorage.getItem(TOKEN_STORAGE_KEY);
  const [fetchedProfile, setFetchedProfile] = useState(false);
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [showAfterProfileModal, setShowAfterProfileModal] = useState(false);

  const redirectToLoginConfirmation = useMemo(() => !!localStorage.getItem(LOGIN_CONFIRMATION_REDIRECT), []);
  const shouldFetchProfile = useMemo(() => {
    return !!isLoggedIn && !!completedAuth && !redirectToLoginConfirmation;
  }, [completedAuth, isLoggedIn, redirectToLoginConfirmation]);

  const {
    data: profile,
    isValidating: profileValidating,
    error,
  } = useSWR<Profile>(shouldFetchProfile ? '/facebook-service/api/posts/check-valid-profile' : null);

  const { analyticsTrack } = useSegement();

  const fetchPermission = async () => {
    try {
      setLoading(true);
      const res = await axios.get(PERMISSIONS_URL);
      setPermissions(res.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const generatePermissionRoutes = () => {
    if (loading || !permissions?.length) {
      return <BlockSpinner />;
    }

    return flatten(
      permissions.map((item: Permission) => {
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        return RoutesGroup[item]?.map((group: any) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const disabledRoutes = rules.disabledRoutes?.[APP_REGION];

          if (disabledRoutes?.includes(group.path)) {
            return null;
          }

          return <Route key={group.path} exact={group.exact} path={group.path} component={group.component} />;
        });
      })
    );
  };

  const generateDefaultRoute = useCallback(() => {
    const avalableRoutes = flatten(
      permissions.map((item: Permission) => {
        return RoutesGroup[item]?.map((group: any) => group.path);
      })
    );
    const havingInvitation = !!localStorage.getItem(CURRENT_INVITATION_CODE);

    if (havingInvitation) {
      return '/invitation';
    }

    if (!isSubadmin() && redirectToLoginConfirmation) {
      return '/confirm-login';
    }

    return avalableRoutes.includes('/live-sessions') ? '/live-sessions' : avalableRoutes[0] || '/';
  }, [permissions, redirectToLoginConfirmation]);

  useEffect(() => {
    isLoggedIn && completedAuth && fetchPermission();
  }, [isLoggedIn, completedAuth]);

  useEffect(() => {
    if (profileValidating) {
      setFetchedProfile(true);
    }
  }, [profileValidating]);

  useEffect(() => {
    if (!isSubadmin() && fetchedProfile && !profileValidating && !profile && !error) {
      setShowProfileForm(true);
    }
  }, [error, fetchedProfile, profile, profileValidating]);

  useEffect(() => {
    if (window.location.pathname === '/' && localStorage.getItem(PREPARE_LOGIN)) {
      analyticsTrack('Logged In');
      localStorage.removeItem(PREPARE_LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PermissionContext.Provider value={permissions || []}>
      <UserSpaceContext.Provider
        value={{
          data: userSpaceContext,
          setData: setUserSpaceContext,
        }}
      >
        {!!showProfileForm && (
          <ProfileSectionModal
            onClose={() => setShowProfileForm(false)}
            setShowAfterProfileModal={setShowAfterProfileModal}
          />
        )}
        {!!showAfterProfileModal && (
          <CompletedProfileModal onClose={() => setShowAfterProfileModal(!showAfterProfileModal)} />
        )}
        <Router>
          <Suspense fallback={<BlockSpinner />}>
            <Switch>
              <Route path="/auth/callback">
                <AuthCallback />
              </Route>
              <Route path="/auth/logout">
                <LogoutCallback />
              </Route>
              <Route path="/auth/redirect">
                <RedirectCallback />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/confirm-login">
                <LoginConfirmation />
              </Route>
              <Route path="/invitation">
                <InvitationPage />
              </Route>
              <Route path="/current-stream/:id">
                <CurrentStreamingProduct />
              </Route>
              <Route exact path="/">
                <Redirect to={isLoggedIn ? generateDefaultRoute() : '/login'} />
              </Route>
              <Route path="/">
                <LayoutTemplate>
                  <Suspense fallback={<BlockSpinner />}>
                    <Switch>
                      {isLoggedIn && (
                        <Route path="/expired">
                          <ExpiredSubscription />
                        </Route>
                      )}
                      <Route path="/payup-kyc" component={PayUpKYC} />
                      {isLoggedIn && generatePermissionRoutes()}
                    </Switch>
                  </Suspense>
                </LayoutTemplate>
              </Route>
            </Switch>
          </Suspense>
          <GoogleAnalytics />
        </Router>
      </UserSpaceContext.Provider>
    </PermissionContext.Provider>
  );
};

export default Routes;
