import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Divider } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';

import T from 'shared-components/Translator';
import { brandColor } from 'utils/colors';
import { LOGIN_CONFIRMATION_REDIRECT, TOKEN_STORAGE_KEY, isPH, isVN } from 'utils/constants';
import { useSegement } from 'utils/hooks';
import SwitchUserModal from './SwitchUserModal';
import BlockSpinner from 'shared-components/BlockSpinner';
import { Me } from 'models/Me';
import { useLogout } from 'hooks/logout';
import LanguagesSelect from 'shared-components/LanguagesSelect';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

const LoginConfirmation: React.FC<Props> = () => {
  const { isAuthenticated } = useAuth0();
  const { analyticsTrack } = useSegement();
  const { logout } = useLogout();

  const [openModal, setOpenModal] = useState(false);
  const isLoggedIn = isAuthenticated || !!localStorage.getItem(TOKEN_STORAGE_KEY);
  const [isValidating, setLoading] = useState(false);
  const [dataResponse, setData] = useState<Me>();
  const [tocAgreed, setTocAgreed] = useState(false);
  const [betaAccessAgreed, setBetaAccessAgreed] = useState(false);
  const canLogin = useMemo(() => {
    if (isPH) {
      return true;
    }
    if (isVN) {
      return tocAgreed;
    }
    return tocAgreed && betaAccessAgreed;
  }, [betaAccessAgreed, tocAgreed]);

  const onChangeToc = (e: CheckboxChangeEvent) => setTocAgreed(e.target.checked);
  const onChangeBetaAccess = (e: CheckboxChangeEvent) => setBetaAccessAgreed(e.target.checked);

  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);

      const res = await axios.get('/user-service/api/users/me', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(TOKEN_STORAGE_KEY)}`,
        },
      });
      setData(res.data);
    } catch (err) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    isLoggedIn && fetchUser();
  }, [fetchUser, isLoggedIn]);

  useEffect(() => {
    try {
      if (!localStorage.getItem(LOGIN_CONFIRMATION_REDIRECT)) {
        const params = new URLSearchParams(window.location.search);
        const redirectUrl = params.get('redirect_url') || '/';
        window.location.replace(redirectUrl);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  }, []);

  const handleNext = () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const redirectUrl = params.get('redirect_url') || '';
      localStorage.removeItem(LOGIN_CONFIRMATION_REDIRECT);

      window.location.replace(redirectUrl);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const handleLogout = () => {
    analyticsTrack('Switch User & Logout');
    localStorage.removeItem(LOGIN_CONFIRMATION_REDIRECT);
    logout();
  };

  return (
    <>
      <SwitchUserModal open={openModal} onClose={() => setOpenModal(false)} onConfirm={handleLogout} />
      <div className="relative flex flex-col items-stretch h-full md:flex-row">
        <div className="absolute right-4 top-4">
          <LanguagesSelect type="dropdown" />
        </div>
        <div
          className="flex flex-col items-center justify-center p-8 bg-brand-glossy md:pr-16"
          style={{ flex: '2 2 auto' }}
        >
          <img alt="logo" src="/images/logo.svg" className="w-48 text-white md:w-full" style={{ maxWidth: 360 }} />
          <div className="mx-auto text-center">
            <h1 className="text-2xl text-white md:text-3xl">
              <T value="upmesh_seller_dashboard" />
            </h1>
          </div>
        </div>
        <div className="flex-1 justify-end flex flex-col items-center py-8 px-8">
          <div className="flex flex-col justify-end" style={{ maxWidth: 600 }}>
            <div className="mb-4 text-xl text-center">
              <T value="login_as" />
            </div>
            <div className="flex flex-col justify-center">
              {isValidating || !dataResponse ? (
                <BlockSpinner />
              ) : (
                <>
                  <div
                    className="flex items-center px-4 py-2 mb-4 border border-brand-500 fade-in"
                    style={{ minWidth: 260 }}
                  >
                    <Avatar className="fade-in" src={dataResponse?.profile?.picture} />
                    <div className="ml-2">
                      <div className="fade-in">{`${dataResponse?.firstName} ${dataResponse?.lastName}`}</div>
                      <div className="text-gray-600 fade-in">{dataResponse?.email}</div>
                    </div>
                  </div>
                  <div>
                    {!isPH && (
                      <div className="mb-2">
                        <div>
                          <Checkbox onChange={onChangeToc}>
                            <T value="accept_term" />
                          </Checkbox>
                        </div>
                        {!isVN && (
                          <div>
                            <Checkbox onChange={onChangeBetaAccess}>
                              <T value="accept_agreement" />
                            </Checkbox>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <Button
                    type="primary"
                    block
                    onClick={handleNext}
                    className="uppercase hover:opacity-75"
                    disabled={!canLogin}
                    size="large"
                    style={{ background: canLogin ? brandColor[5] : brandColor[3] }}
                  >
                    <T value="next" />
                  </Button>
                  <Button className="mt-1 hover:opacity-75" type="link" onClick={() => setOpenModal(true)}>
                    <T value="switch_user" />
                  </Button>
                  <Divider type="horizontal" />
                  <Button
                    type="link"
                    href={process.env.REACT_APP_PRIVACY_POLICY_URL}
                    rel="noreferrer"
                    target="_blank"
                    style={{ fontSize: '12px' }}
                  >
                    <T value="privacy_policy" />
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginConfirmation;
