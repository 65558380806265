/* eslint-disable @typescript-eslint/no-explicit-any */
import useSWR from 'swr';
import { IDeliveryStatus, DeliveryStatus } from 'models/DeliveryStatus';
import { BusinessProfiles, Profile } from 'models/Profile';
import UserSettings from 'models/UserSettings';
import { useShowError } from 'utils/hooks';
import { useCallback, useContext, useState } from 'react';
import { LanguageContext } from 'context/language';
import { requestWithErrorLogging } from 'utils/request';
import { getGenericErrors } from 'utils/error';
import { message } from 'antd';
import { generateCommonTrackingData } from 'utils/functions';

export const useProfile = () => {
  return useSWR<Profile>('/profile-service/api/profile');
};

export const useFetchPaidOrderStatuses = () => {
  return useSWR<IDeliveryStatus[]>('/profile-service/api/delivery-status-settings');
};

export const useFetchEnabledStatuses = () => {
  const { data: deliveryStatuses, isValidating: isLoadingDeliveryStatuses } = useSWR<{ status: DeliveryStatus }[]>(
    '/profile-service/api/delivery-status-settings/enabled'
  );

  return { deliveryStatuses, isLoadingDeliveryStatuses };
};

export const useGetBusinessProfiles = (id?: string) => {
  const result = useSWR<BusinessProfiles>(id ? `/urus-shipment-service/business-profiles/${id}` : null);

  return {
    ...result,
    isLoading: !result.error && !result.data,
    data: result?.data,
  };
};

export const useFetchUserSettings = (enable = true) => {
  const {
    data: userSettings,
    revalidate,
    isValidating,
  } = useSWR<UserSettings>(enable ? '/profile-service/api/profile/user-settings' : null);

  return {
    enableNotification: userSettings?.notificationSetting,
    confirmUpdateMessageStatus: userSettings?.confirmUpdateMessageStatus,
    invoice: userSettings
      ? {
          globalInvoiceNote: userSettings?.globalInvoiceNote,
          invoiceDownloadMode: userSettings?.invoiceDownloadMode,
          invoiceProductSortRule: userSettings?.invoiceProductSortRule,
          invoiceHeaderDisplayMode: userSettings?.invoiceHeaderDisplayMode,
        }
      : undefined,
    termAndCondition: userSettings?.termAndCondition,
    cartExpiry: userSettings
      ? {
          enableCartExpiry: userSettings.enableCartExpiry,
          cartExpiry: userSettings.cartExpiry,
        }
      : undefined,
    sellingCodeRule: userSettings?.sellingCodeRule,
    flexibleCharacterOfVariationFirstRuleNumber: userSettings?.flexibleCharacterOfVariationFirstRuleNumber || 0,
    flexibleCharacterOfVariationSecondRuleNumber: userSettings?.flexibleCharacterOfVariationSecondRuleNumber || 0,
    revalidate,
    isLoading: isValidating,
  };
};

export const useCheckIsFreelancer = (disable?: boolean) => {
  const { data: data, isValidating } = useSWR<{ isFreelancer: boolean }>(
    disable ? null : '/user-service/api/workspace/fillup-user',
    {
      dedupingInterval: 3600000, // 1 hour
    }
  );

  return {
    isFreelancer: data?.isFreelancer,
    isValidating,
  };
};

export const useGetLinkToNavigateToOdoo = ({ enable }: { enable: boolean }) => {
  const { data, isValidating } = useSWR<{ url: string }>(
    enable ? 'profile-service/api/profile/odoo-auth/facebook' : null
  );
  return { data: data?.url, isValidating };
};

export const useUpdateOOS = ({ onSuccess }: { onSuccess: (trackingEventData?: any) => void }) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [updating, setUpdating] = useState<boolean>(false);

  const handleUpdate = useCallback(
    async (isEnableOOTMessage: boolean) => {
      const trackingEventData = generateCommonTrackingData();
      setUpdating(true);
      try {
        await requestWithErrorLogging({
          url: `/profile-service/api/profile/oot-message`,
          method: 'patch',
          data: { enableOOTMessage: isEnableOOTMessage },
          mutateConfig: {
            mutatePath: ['/profile-service/api/profile'],
          },
        });
        onSuccess(trackingEventData);
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('update_oos_error'), translate('update_oos_error_description'));
      } finally {
        setUpdating(false);
      }
    },
    [onSuccess, showError, translate]
  );

  return { handleUpdate, updating };
};

export const useUpdateUserSetting = ({
  onSuccess,
}: {
  onSuccess: (req: UserSettings, trackingEventData?: any) => void;
}) => {
  const { showError } = useShowError();
  const { translate } = useContext(LanguageContext);

  const [updating, setUpdating] = useState<boolean>(false);

  const handleUpdate = useCallback(
    async (data: UserSettings) => {
      setUpdating(true);
      const commonTrackingEventData = generateCommonTrackingData();
      try {
        await requestWithErrorLogging({
          url: `/profile-service/api/profile/user-settings`,
          method: 'patch',
          data,
        });
        message.success(translate('settings_update_successfully'));
        onSuccess(data, commonTrackingEventData);
      } catch (err) {
        const genericErrors = getGenericErrors(err);
        showError(genericErrors, translate('save_settings_error'), translate('save_settings_error_description'));
      } finally {
        setUpdating(false);
      }
    },
    [onSuccess, showError, translate]
  );

  return { handleUpdate, updating };
};
